import React from 'react'
import './styles/Banner.scss'
import banner from '../assets/banner.jpg'

const Banner = () => {
  return (
    <div className='container-banner' id="Home">
      <div className='banner-imagen'>
        {/* <img src={banner} alt="Image by StockSnap from Pixabay" /> */}
      </div>
      <div className="banner-texto">
        <h1>Omar David Bustamante Gonzalez</h1>
        <p>
          Passionate developer with expertise in frontend technologies, eager to expand into fullstack development and data science.
        </p>
        <p>
          Committed to continuous learning and leveraging innovative solutions to solve complex problems.
        </p>
        <p>
          Let's build something amazing together.
        </p>
      </div>
    </div>
  )
}

export default Banner

/*
  Desarrollador apasionado con experiencia en tecnologías frontend, ansioso por expandirse en el desarrollo fullstack y la ciencia de datos.
   Comprometido con el aprendizaje continuo y el aprovechamiento de soluciones innovadoras para resolver problemas complejos.
   ¡Construyamos algo increíble juntos!
*/