import React from 'react'
import Navbar from '../components/Navbar'
import Banner from '../screens/Banner'
import Projects from '../screens/Projects'
import Tecnologies from '../screens/Tecnologies'
import Form from '../screens/Form'

const HomePage = () => {
  return (
    <>
      <Navbar />
      <Banner />
      <Projects />
      <Tecnologies />
      <Form />
    </>
  )
}

export default HomePage