import React from 'react'
import Navbar from '../components/Navbar'
import Form from '../screens/Form'

const Contact = () => {
  return (
    <>
      <Navbar />
      <Form />
    </>
  )
}

export default Contact