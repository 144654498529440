import React from 'react'
import './styles/Card.scss'

const Card = (props) => {
  return (
    <div className='card'>
      <a href={props.url} target="_blank" rel="noreferrer noopener"><div className='imagen' style={{'background-image': `url(${props.img})`}}></div></a>
      <a href={props.url} target="_blank" rel="noreferrer noopener"><h2>{props.titulo}</h2></a>
      <p>{props.desc}</p>
      <div className='tec'>
        <span>{props.tec1}</span>
        <span>{props.tec2}</span>
        <span>{props.tec3}</span>
        <span>{props.tec4}</span>
      </div>
    </div>
  )
}

export default Card