import React from 'react'
import './styles/Tecnologies.scss'
import { FaHtml5 } from "react-icons/fa";
import { FaCss3Alt } from "react-icons/fa6";
import { IoLogoJavascript } from "react-icons/io5";
import { FaReact } from "react-icons/fa";
import { FaPython } from "react-icons/fa";
import { SiMysql } from "react-icons/si";
import { FaSass } from "react-icons/fa";
import { SiCsharp } from "react-icons/si";
import { FaPhp } from "react-icons/fa";
import { FaGitAlt } from "react-icons/fa";
import { VscVscode } from "react-icons/vsc";
import { FaNodeJs } from "react-icons/fa";

const Tecnologies = () => {
  return (
    <div className='container-tecnologies'>
      <FaHtml5 size={50} />
      <FaCss3Alt size={50} />
      <FaSass size={50} />
      <IoLogoJavascript size={50} />
      <FaNodeJs size={50} />
      <FaReact size={50} />
      <FaPython size={50} />
      <SiCsharp size={50} />
      <FaPhp size={50} />
      <SiMysql size={50} />
      <FaGitAlt size={50} />
      <VscVscode size={50} />
    </div>
  )
}

export default Tecnologies