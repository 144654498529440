import React, { useRef } from 'react'
import './styles/Form.scss'
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import emailjs from '@emailjs/browser'

const Form = () => {
  const refForm = useRef()
  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_p7wmjyt',
        'template_scvrwg9',
        refForm.current,
        {
          publicKey: 'Otdeqo_g7Tkg6_CE-'
        }
      )
      .then (
        () => {
          alert('Message successfully sent!')
        },
        () => {
          alert('Failed to send the message, please try again')
        }
      )
  }

  return (
    <div className='container-form-info' id='Contact'>
      <div className='container-info'>
        <h1>Get in contact</h1>
        <h2 className='info-email'><MdEmail /> bugon61@gmail.com</h2>
        <h2 className='info-tel'><FaPhoneAlt /> 662-111-0968</h2>
        <h2 className='info-city'><FaLocationDot /> Hermosillo, Sonora</h2>
        <div className='socials'>
          <a href='https://www.linkedin.com/in/omar-david-b-a02991243' target="_blank" rel="noreferrer noopener"><FaLinkedin /></a>
          <a href='https://www.github.com/Bugon61' target="_blank" rel="noreferrer noopener"><FaGithub /></a>
        </div>
      </div>
      <div className='container-form'>
        <h1>Send me a message</h1>
        <form ref={refForm} onSubmit={sendEmail}>
          <input type="text" id="name" placeholder='Name' name='name' required />
          <input type="email" id="email" placeholder='Email' name='email' required />
          <textarea
            placeholder='Message'
            name='message'
            required
          >
          </textarea>
          <input type='submit' className='send-button' value='SEND' />
        </form>
      </div>
    </div>
  )
}

export default Form