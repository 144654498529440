import React, {useState} from 'react'
import './styles/Navbar.scss'
import { Link, NavLink } from 'react-router-dom'
import ALink from './ALink'
import { GoHomeFill } from "react-icons/go";
import { CgWebsite } from "react-icons/cg";
import { RiContactsBook2Fill } from "react-icons/ri";
import { IoMenu } from "react-icons/io5";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false)
  const options = {
    'Home': true,
    'Projects': false,
    'Contact': false
  }
  const menuOptions = [
    {
      text: 'Home',
      icon: <GoHomeFill />
    },
    {
      text: 'Projects',
      icon: <CgWebsite />
    },
    {
      text: 'Contact',
      icon: <RiContactsBook2Fill />
    },
  ]

  const changeActive = () => {
    const optHome = document.querySelector('#optHome')
    const optProjects = document.querySelector('#optProjects')
    const optContact = document.querySelector('#optContact')

    if(options['Home']){
      optHome.className = 'active'
    } else{
      optHome.className = ''
    }
    if(options['Projects']){
      optProjects.className = 'active'
    } else{
      optProjects.className = ''
    }
    if(options['Contact']){
      optContact.className = 'active'
    } else{
      optContact.className = ''
    }
  }


  return (
    <header className='navbar'>
      <div className='container'>
        <NavLink 
          to="/" 
          className="logo"
        ><h1>Bugon</h1></NavLink>
        <nav className='links'>
          <div className='links-list'>
            <ul>
              {/* <li>
                <NavLink 
                  to="/" 
                  className={({ isActive, isPending}) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                > Home </NavLink>
              </li>
              <li>
                <NavLink 
                  to="/About" 
                  className={({ isActive, isPending}) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                > About </NavLink>
              </li>
              <li>
                <NavLink 
                  to="/Contact" 
                  className={({ isActive, isPending}) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                > Contact </NavLink>
              </li> */}
              <li>
                <a 
                  id='optHome'
                  href='#'
                  onClick={() => {
                    Object.keys(options).forEach((k) => {k === 'Home' ? options[k] = true : options[k] = false})
                    changeActive()
                  }}
                  className={options['Home'] ? 'active' : ''}
                >Home</a>
              </li>
              <li>
                <a 
                  id='optProjects'
                  href='#Projects'
                  onClick={() => {
                    Object.keys(options).forEach((k) => {k === 'Projects' ? options[k] = true : options[k] = false})
                    changeActive()
                  }}
                  className={options['Projects'] ? 'active' : ''}
                >Projects</a>
              </li>
              <li>
                <a 
                  id='optContact'
                  href='#Contact'
                  onClick={() => {
                    Object.keys(options).forEach((k) => {k === 'Contact' ? options[k] = true : options[k] = false})
                    changeActive()
                  }}
                  className={options['Contact'] ? 'active' : ''}
                >Contact</a>
              </li>
            </ul>
          </div>
          <div className='links-menu'>
            <IoMenu size={40} onClick={() => setOpenMenu(true) } />
          </div>
          <Drawer open={openMenu} onClose={() =>
            setOpenMenu(false)
          } anchor='right' >
            <Box
              sx={{ width: 200 }}
              role="presentation"
              onClick={() => setOpenMenu(false)}
              onKeyDown={() => setOpenMenu(false)}
              style={{'background-color' : '#35374B' , 'height':'100vh'}}
            >
              <List className='links-drawer'>
                {menuOptions.map((item) => (
                  <ListItem key={item.text} disablePadding>
                    <ListItemButton>
                      <a href={'#' + item.text}>
                        <ListItemIcon style={{'color':'#fff'}}>{item.icon}</ListItemIcon>
                        <ListItemText style={{'color':'#fff'}} primary={item.text} />
                      </a>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
        </nav>
      </div>
    </header>
  )
}

export default Navbar